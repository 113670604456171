import React from "react";
import VideoComponent from "../VideoComponent";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CardFlex = ({ data, isReverse }) => {
  const labelItems = data?.items;

  const itemStyle =
    "bg-gray-2800 border border-gray-1600 rounded-xl sm:rounded-2xl flex- flex-col";

  const lablePadding = "sm:px-5 px-4 py-6";

  const lableStyle =
    "uppercase tracking-widest_1 group-hover:text-purple-1500 font-medium text-gray-600 lg:mb-2.5 mb-2 sm:text-base text-xs";

  const headingStyle =
    "font-manrope sm:text-2.5xl text-2xl !tracking-tighter_3 text-gray-2700 font-semibold mb-3";

  const descriptionStyle =
    "sm:text-base text-sm text-gray-600 !tracking-tighter_3";

  return (
    <div className="px-4 pb-12 sm:px-6 lg:px-10 ">
      <div
        className={`flex gap-2.5 ${isReverse ? "lg:flex-row-reverse flex-col-reverse" : "lg:flex-row flex-col"}`}
      >
        <div className={`${itemStyle} ${lablePadding} lg:lg:w-1/2`}>
          {labelItems[0]?.card_video ? (
            <VideoComponent
              src={labelItems[0]?.card_video?.url}
              className="w-auto h-full mt-5"
            />
          ) : (
            <GatsbyImage
              image={getImage(labelItems[0]?.card_image)}
              width={labelItems[0]?.card_image?.dimensions?.width || 0}
              height={labelItems[0]?.card_image?.dimensions?.height || 0}
              alt={labelItems[0]?.card_label?.text || "image"}
              className="w-full h-auto mt-5"
              loading="lazy"
            />
          )}
          <div
            className={`${headingStyle} ${isReverse ? "lg:mt-0" : "lg:mt-6"}`}
          >
            {labelItems[0]?.card_heading?.text}
          </div>
          <div className={descriptionStyle}>
            {labelItems[0]?.card_description?.text}
          </div>
        </div>
        <div className="flex group flex-col gap-2.5 lg:w-1/2">
          <div className={`${lablePadding} ${itemStyle}`}>
            {labelItems[1]?.card_video ? (
              <VideoComponent
                src={labelItems[1]?.card_video?.url}
                className="w-full h-auto mx-auto mt-2.5 mb-5"
              />
            ) : (
              <GatsbyImage
                image={getImage(labelItems[1]?.card_image)}
                width={labelItems[1]?.card_image?.dimensions?.width || 0}
                height={labelItems[1]?.card_image?.dimensions?.height || 0}
                alt={labelItems[1]?.card_label?.text || "card Image"}
                className="mx-auto w-full mt-2.5 mb-5"
                loading="lazy"
              />
            )}
            <div className={headingStyle}>
              {labelItems[1]?.card_heading?.text}
            </div>
            <div className={descriptionStyle}>
              {labelItems[1]?.card_description?.text}
            </div>
          </div>
          <div className={`${lablePadding} ${itemStyle}`}>
            {labelItems[2]?.card_video ? (
              <VideoComponent
                src={labelItems[1]?.card_video?.url}
                className="w-full h-auto mx-auto mt-2.5 mb-5"
              />
            ) : (
              <GatsbyImage
                image={getImage(labelItems[2]?.card_image)}
                width={labelItems[2]?.card_image?.dimensions?.width || 0}
                height={labelItems[2]?.card_image?.dimensions?.height || 0}
                alt={labelItems[2]?.card_label?.text || "card_image"}
                className="mx-auto w-full mt-2.5 mb-5"
                loading="lazy"
              />
            )}
            <div className={headingStyle}>
              {labelItems[2]?.card_heading?.text}
            </div>
            <div className={descriptionStyle}>
              {labelItems[2]?.card_description?.text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFlex;
